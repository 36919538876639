import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { REGISTER_API } from "../routes/backend";

function Register() {
  const { register, handleSubmit, errors, reset } = useForm();
  const history = useHistory();
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(REGISTER_API, data);
      console.log('👉 Returned data:', response.data);
      history.push("/dashboard")
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
    reset();
  };

  return (
    <div className="container">
      <div className="box">
        <h2 className="heading">Create Account</h2>
        <p className="subheading">
          Please sign up for your personal account to get started.
        </p>
        <form>
          <button type="submit" style={{ backgroundColor: "#f96d80" }}>
            <i className="fab fa-google" /> Continue with Google
          </button>
        </form>
        <form onSubmit={handleSubmit(onSubmit)}>

          <p className="inBetweenPara">Or continue with email</p>
          <input
            type="text"
            placeholder="Name"
            name="displayName"
            ref={register({ required: "This field is required" })}
          />
          {errors.name && <p>{errors.name.message}</p>}
          <input
            type="email"
            placeholder="Email"
            name="email"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
          <input
            type="password"
            placeholder="Password"
            name="password"
            ref={register({
              required: "This field is required",
              minLength: {
                value: 8,
                message: "Too Short, min 8 characters required",
              },
            })}
          />
          {errors.password && <p>{errors.password.message}</p>}
          <input
            type="text"
            placeholder="Referral"
            name="referral"
            ref={register({ required: "This field is required" })}
          />
          {errors.referral && <p>{errors.referral.message}</p>}
          <button type="submit">Get Started</button>
          <p>
            Already have an Account?{" "}
            <Link to="/login" className="sign-link">
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Register;
