import React from "react";

function Header() {
  return (
    <header>
      <a href="/" className="brand">
        SwitchSimply
      </a>
    </header>
  );
}

export default Header;
