import React from "react";
//import Typography from "@material-ui/core";

function Footer() {
  return (
    <footer>
      <p className="footer-text">@ Copyright SwitchSimply 2020</p>
    </footer>
  );
}

export default Footer;
