import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import { LOGIN_API } from "../routes/backend";

function Login() {
  const { register, handleSubmit, errors, reset } = useForm();
  const history = useHistory();
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(LOGIN_API, data)
      console.log('👉 Returned data:', response.data);
      history.push("/dashboard")
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
    reset();
  };
  return (
    <div className="container">
      <div className="box">
        <h2 className="heading">Sign in to SwitchSimply</h2>
        <p className="subheading">Please use your registered Google Account</p>
        <form>
          <button type="submit" style={{ backgroundColor: "#f96d80" }}>
            <i className="fab fa-google" /> Continue with Google
          </button>
        </form>
        <form onSubmit={handleSubmit(onSubmit)} action="POST">
          <p className="inBetweenPara">
            Or continue with your registered Email
          </p>
          <input
            type="email"
            placeholder="Email"
            name="email"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
          <input
            type="password"
            placeholder="Password"
            name="password"
            ref={register({
              required: "This field is required",
            })}
          />
          {errors.password && <p>{errors.password.message}</p>}
          <button type="submit">Login</button>
          <p>
            Don't have an Account?{" "}
            <Link to="/register" className="sign-link">
              Sign Up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
